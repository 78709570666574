import React, { useEffect, useState } from "react";
import api from "~/services/api";
import { LandPage, ContainerComm, Main, ThisCarregamento, ThisProgress } from './styles';
import { Colors } from "~/config/Colors";
import { useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import { Materialize } from "~/styles/materializeGrid";

import { Label, Input, Select as SelectCF } from "~/componentes/CustomForm";
import IconInsatisfeito from '~/assets/icones/icon_insatisfeito.png';
import IconMuitoInsatisfeito from '~/assets/icones/icon_muito_insatisfeito.png';
import IconSatisfeito from '~/assets/icones/icon_satisfeito.png';
import IconMuitosatisfeito from '~/assets/icones/icon_muito_satisfeito.png';
import IconNormal from '~/assets/icones/icon_muito_normal.png';

import Select, { StylesConfig } from 'react-select';
import GaugeChart from 'react-gauge-chart';
import { renderToString } from "react-dom/server";
import { jsPDF } from 'jspdf';
import { store } from "~/store";
import { loadingToggle } from "~/store/modules/loadingModal/actions";
import NunitoSemiBold from '~/utils/Nunito-semiBold.js';
import PainelPDF from "./Template/PainelPDF";
import moment from "moment";
import TableComponent from "./TableComponent";

export default function Painel() {
  const dispatch = store.dispatch;
  const [loadedData, setLoadedData] = useState(false);
  const { perfil } = useSelector(state => state.auth);
  const [chartPrincipal, setChartPrincipal] = useState([]);
  const [dataDe, setDataDe] = useState(new Date(new Date().getFullYear(), new Date().getMonth()-1, 1).toISOString().slice(0, 10));
  const [dataAte, setDataAte] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10));
  const [pesquisas, setPesquisas] = useState();
  const [idPesquisa, setIdPesquisa] = useState();
  const [centroCustos, setCentroCustos] = useState();
  const [idCentroCusto, setIdCentroCusto] = useState([]);
  const [servicos, setServicos] = useState();
  const [idServico, setIdServico] = useState([]);
  const [imprimePDF, setImprimePDF] = useState(false);
  const [atualiza, setAtualiza] = useState(false);
  const [data, setData] = useState([]);
  const [loadedDataComm, setLoadedDataComm] = useState(false);
  var cronometro = 1;
  
  const cores = [
    "#2E7AD3",
    "#FF5780",
    "#802ED3",
    "#C62ED3",
    "#CAC4C8",
    "#CE6D14",
    "#D32EA5",
    "#14ADCE",
    "#4691C7",
    "#FF9090",
    "#D4F37B",
    "#FFF27E",
    "#4F4F4F",
    "#B9FFDE",
    "#B9D1FF",
  ];

  useEffect(() => {
    api
      .get('/signed/cliente/pesquisa/dashboard/painel', { params: { dataDe, dataAte, idCentroCusto, idServico, idPesquisa } })
      .then(response => setChartPrincipal(response.data));
      
  }, [dataDe, dataAte, idCentroCusto, idServico, idPesquisa]);

  useEffect(() => {
    setLoadedData(false);
    setLoadedDataComm(false);
  }, [perfil]);

  useEffect(() => {
    api.get('/signed/cliente/gestorservico/gerenciamento/centroCustos', { params: { ativo: 1 } })
      .then(response => {
        let lista = [];
        for (const item of response.data) {
          lista.push({  value: item?.id, label: item?.titulo, color: "#00B8D9"})
        }
        setCentroCustos(lista);
      });

    api.get('/signed/cliente/pesquisa/gerenciamento/pesquisas', { params: { ativo: 1 } })
      .then(response => {
        setPesquisas(response.data);

        if (response.data.length > 0) {
          setIdPesquisa(response.data[0].id);
        }
      });

    api.get('/signed/cliente/gestorservico/gerenciamento/servicos', { params: { ativo: 1 } })
      .then(response => {
        let lista = [];
        for (const item of response.data) {
          lista.push({  value: item?.id, label: item?.titulo, color: "#00B8D9"})
        }
        setServicos(lista);
      });
      
    setInterval(() => {
      if (cronometro == 10) {
        cronometro = 1;
        setAtualiza(true);
      } else {
        cronometro = cronometro + 1;
      }
      
    }, 1000);
  }, []);

  const handleDownloadPainel = async () => {
    dispatch(loadingToggle());

    let tituloPesquisa = pesquisas.find((e) => e?.id == idPesquisa);
    let tituloPeriodo = moment(dataDe, "YYYY-MM-DD").format('DD/MM/YYYY') + " Até " + moment(dataAte, "YYYY-MM-DD").format('DD/MM/YYYY');
    let tituloTurno = "";
    let listaCCs = "";

    let count = 0;
    for (const itemCC of idCentroCusto) {
      const itemIdCC = centroCustos.find((e) => e?.value == itemCC);

      if (count == 0) {
        tituloTurno += itemIdCC?.label;
      } else {
        tituloTurno += ", " + itemIdCC?.label;
      }
      
      count++;
    }

    count = 0;
    for (const itemServ of idServico) {
      const itemIdServ = servicos.find((e) => e?.value == itemServ);

      if (count == 0) {
        listaCCs += itemIdServ?.label;
      } else {
        listaCCs += ", " + itemIdServ?.label;
      }

      count++;
    }

  
    let htmlElement = <PainelPDF data={{
      resultado: chartPrincipal,
      tituloPesquisa: tituloPesquisa?.titulo,
      tituloPeriodo,
      nomeUnidade: "",
      tituloTurno,
      listaCCs,
      params: { dataDe, dataAte, idCentroCusto, idServico, idPesquisa, comentario: "S" },
      data,
    }} />;
    
    let elementAsString = renderToString(htmlElement);

    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      orientation: 'p'
    });
    doc.addFileToVFS('Nunito-semibold.ttf', NunitoSemiBold);
    doc.addFont('Nunito-semibold.ttf', 'Nunito', 'normal');
    doc.setFont('Nunito');

    doc.setFontSize(10);
    doc.html(elementAsString, {
      async callback(doc) {
        window.open(URL.createObjectURL(doc.output("blob")));
        dispatch(loadingToggle());
      },
    });
    // doc.save("two-by-four.pdf");
  };

  useEffect(() => {
    if (imprimePDF) {
      setImprimePDF(false);
      handleDownloadPainel();
    }
  }, [imprimePDF]);

  useEffect(() => {
    if (atualiza) {
      api
        .get('/signed/cliente/pesquisa/dashboard/painel', { params: { dataDe, dataAte, idCentroCusto, idServico, idPesquisa } })
        .then(response => setChartPrincipal(response.data));
      setAtualiza(false);
      setLoadedDataComm(false);
    }
  }, [atualiza]);

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? Colors.Color2
          : undefined,
        color: Colors.Color7,
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : Colors.Color2
            : undefined,
        },
      };
    },
  };

  return (
    <Main>
      <LandPage>
        <div className="content">
          <Materialize className="materialize">
            <div className="row header">
              <div className="divTitle">
                <div className="div-icone">
                  <h4 className="title">Painel</h4>
                  <h5 className="sub-title">Visualize as informações e tenha uma análise prévia das pesquisas</h5>
                </div>
              </div>
              <div className="div-bt">
                <button
                  className="btn-filtro"
                  onClick={() => setImprimePDF(true)}
                >
                  PDF
                </button>
              </div>
            </div>
            <div className="row filtro">
              <Label title="" className="col s2 m2 l2 no-padd-l">
                <SelectCF
                  isNumber
                  list={{ list: pesquisas, value: 'id', label: 'titulo' }}
                  name="idPesquisa"
                  firstOption={"Pesquisa"}
                  defaultValue={idPesquisa}
                  onChange={(e) => {
                    const v = e.currentTarget.value;
                    setIdPesquisa(v);
                  }}
                />
              </Label>
              <Label title="" className="col s3 m3 l3">
                <Select
                  defaultValue={centroCustos}
                  isMulti
                  name="colors"
                  options={centroCustos}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Centro de Custos"
                  onChange={(e) => {
                    let ids = [];
                    for (const itemE of e) {
                      ids.push(itemE?.value);
                    }
                    setIdCentroCusto(ids);
                  }}
                  styles={colourStyles}
                />
              </Label>
              <Label title="" className="col s3 m3 l3">
                <Select
                  defaultValue={servicos}
                  isMulti
                  name="colors"
                  options={servicos}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Turnos"
                  onChange={(e) => {
                    let ids = [];
                    for (const itemE of e) {
                      ids.push(itemE?.value);
                    }
                    setIdServico(ids);
                  }}
                  styles={colourStyles}
                />
                {/* <SelectCF
                  isNumber
                  list={{ list: servicos, value: 'id', label: 'titulo' }}
                  name="idServico"
                  firstOption={"Turnos"}
                  onChange={(e) => {
                    const v = e.currentTarget.value;
                    setIdServico(v);
                  }}
                /> */}
              </Label>
              <Label title="" className="col s2 m2 l2">
                <Input
                  type="date"
                  name="dataDe"
                  defaultValue={dataDe}
                  onBlur={(e) => {
                    const v = e.currentTarget.value || "";
                    setDataDe(v);
                  }}
                />
              </Label>
              <Label title="" className="col s2 m2 l2">
                <Input
                  type="date"
                  name="dataAte"
                  defaultValue={dataAte}
                  onBlur={(e) => {
                    const v = e.currentTarget.value || "";
                    setDataAte(v);
                  }}
                />
              </Label>
            </div>
          </Materialize>
          <div className="back-section">
            <ThisCarregamento></ThisCarregamento>
            {chartPrincipal?.map((itemPergunta, i) => (
              <div className="section-1">
                <div className="chart-barra">
                  <div className="title">
                    {i + 1}. {itemPergunta?.titulo}
                  </div>
                  <div className="list">
                    {itemPergunta?.listaItens?.map((itemItem, a) => (
                      <div className="item-pergunta">
                        {
                          itemPergunta?.tipo == "SF" && (
                            itemItem?.estrela == "1"
                              ? (
                                <div className="col-1-1 align-left">
                                  <img src={IconMuitosatisfeito} alt="Vazio" />
                                </div>
                              ) : itemItem?.estrela == "2" ? (
                                <div className="col-1-1 align-left">
                                  <img src={IconSatisfeito} alt="Vazio" />
                                </div>
                              ) : itemItem?.estrela == "3" ? (
                                <div className="col-1-1 align-left">
                                  <img src={IconNormal} alt="Vazio" />
                                </div>
                              ) : itemItem?.estrela == "4" ? (
                                <div className="col-1-1 align-left">
                                  <img src={IconInsatisfeito} alt="Vazio" />
                                </div>
                              ) : (
                                <div className="col-1-1 align-left">
                                  <img src={IconMuitoInsatisfeito} alt="Vazio" />
                                </div>
                              )
                          )
                        }
                        <div className="col-3">
                          <p className="titulo-item">{itemItem?.titulo}</p>
                        </div>
                        <div className="col-3">
                          <div className="progress">
                            {
                              itemPergunta?.tipo == "SF" ? (
                                itemItem?.estrela == "1"
                                  ? (
                                    <ThisProgress backgound={'#2ED3B5'} width={itemItem?.porcentagem + "%"} />
                                  ) : itemItem?.estrela == "2" ? (
                                    <ThisProgress backgound={'#B6DF53'} width={itemItem?.porcentagem + "%"} />
                                  ) : itemItem?.estrela == "3" ? (
                                    <ThisProgress backgound={'#FFD147'} width={itemItem?.porcentagem + "%"} />
                                  ) : itemItem?.estrela == "4" ? (
                                    <ThisProgress backgound={'#FFAB48'} width={itemItem?.porcentagem + "%"} />
                                  ) : (
                                    <ThisProgress backgound={'#FF5780'} width={itemItem?.porcentagem + "%"} />
                                  )
                              ) : (
                                <ThisProgress backgound={cores[a]} width={itemItem?.porcentagem + "%"} />
                              )
                            }
                          </div>
                        </div>
                        <div className="col-1 align-right">
                          <p className="titulo-item">{itemItem?.quantidade}</p>
                        </div>
                        <div className="col-1-1 align-center">
                          <p className="titulo-item">|</p>
                        </div>
                        <div className="col-1-1 align-right">
                          <p className="titulo-item">{itemItem?.porcentagem?.toFixed(1)}%</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {
                  itemPergunta?.tipo == "SF" && (
                    <div className="chart-total">
                      <div className="chart-tt-circle">
                        <GaugeChart id="gauge-chart5"
                          // nrOfLevels={420}
                          arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
                          colors={['#FF5780', '#FFAB48', '#FFD147', '#B6DF53', '#2ed3b5']}
                          textColor={Colors.Color3}
                          percent={itemPergunta?.percentualSatisfacao/100}
                          arcPadding={0.02}
                          needleColor={localStorage.getItem("tema") == "E" ? Colors.Color3 : Colors.Color3}
                          needleBaseColor={localStorage.getItem("tema") == "E" ? Colors.Color3 : Colors.Color3}
                          style={{ width: '90%' }}
                          // animDelay={2000}
                          // animateDuration={10000}
                        />
                        <p className="ct-title">Satisfação</p>
                        <p className="ct-quant">{itemPergunta?.quantidade + " respostas"}</p>
                      </div>
                      {/* <div className="chart-tt-circle">
                        <p className="ct-percent">{itemPergunta?.percentualSatisfacao}%</p>
                        <div className={"divisor " + (itemPergunta?.percentualSatisfacao >= 80 ? "dv-colo1" : itemPergunta?.percentualSatisfacao >= 60 ? "dv-colo2" : itemPergunta?.percentualSatisfacao >= 40 ? "dv-colo3" : itemPergunta?.percentualSatisfacao >= 20 ? "dv-colo4" : "dv-colo5")}></div>
                        
                      </div> */}
                    </div>
                  )
                }
              </div>
            ))}
            <div className="section-2">
              <ContainerComm
                requests={{
                  findAll: () =>
                    api.get('/signed/cliente/pesquisa/relatorio/contagens', {
                      params: { dataDe, dataAte, idCentroCusto, idServico, idPesquisa, comentario: "S" }
                    }),
                }}
                setter={setData}
                setLoaded={setLoadedDataComm}
                loaded={loadedDataComm}
                pageSize={10}
                inputs={[
                  { filter: 'pesquisa.titulo', label: 'Pesquisa' },
                  { filter: 'texto', label: 'Comentário' },
                  { filter: 'idAcesso', label: 'Dispositivo' },
                  { filter: 'servico.titulo', label: 'Serviço' },
                  { filter: 'id', label: 'Id' },
                  { filter: 'dataCadastro', label: 'Data' },
                ]}
              >
                <TableComponent />
              </ContainerComm>
            </div>
          </div>
        </div>
      </LandPage>
    </Main>
  );
}
