import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";

function TableComponent() {
  return (
    <>
      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell className="col-w-1" name="id" inputfilter>
            Id
          </SLTableCell>
          <SLTableCell className="col-w-2" name="dispositivo.idAcesso" inputfilter>
            Dispositivo
          </SLTableCell>
          <SLTableCell className="col-w-4 truncate" name="pesquisa.titulo" inputfilter>
            Pesquisa
          </SLTableCell>
          <SLTableCell className="col-w-1" name="dataCadastro" inputfilter>
            Data
          </SLTableCell>
          <SLTableCell className="col-w-3 truncate" name="servico.titulo" inputfilter>
            Serviço
          </SLTableCell>
          <SLTableCell className="col-w-7" name="texto" inputfilter>
            Comentario
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
