import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 0.6em;

  .subMenu-button {
    margin-bottom: 0.5em;
  }
`;
