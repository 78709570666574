import React, { useContext } from "react";
import Label from "~/componentes/CustomForm/Label";
import { Input } from "~/componentes/CustomForm";
import ColoredButton from "~/componentes/Button/Styles/ColoredButton";
import SingleValueList from "~/componentes/SingleValueList";
import sistemaListStore from "../../../../../../componentes/SistemaList/ThisMainComponent/thisStore";
import { useState } from "react";
import { useEffect } from "react";
import api from "~/services/api";
import FindKeyValue from "~/utils/FindKeyValue";

import { Container } from "./styles";
import IconButton from "~/componentes/Button/Styles/IconButton";

function ItensList({ defaultData, setter, data, disabled }) {
  const [responsaveis, setResponsaveis] = useState([]);
  const [responsavel, setResponsavel] = useState({ titulo: "", id: 0, ordem: 0 });
  const [dfSelected, setDfSelected] = useState([]);
  const [dfAvailable, setDfAvailable] = useState([]);
  const [upgradeSetter, setUpgradeSetter] = useState(false);
  const { state } = useContext(sistemaListStore);
  const { edit, editShow } = state?.modal || {};

  function handleAddResponsavel() {
    if (responsavel?.titulo) {
      const checkResponsavel = dfSelected?.find((e) => e?.titulo === responsavel?.titulo);

      if (!checkResponsavel) {
        setter((prev) => [...prev, responsavel]);
      }
    }
  }

  function handleRemoveResponsavel(data) {
    setter((prev) => {
      const newPrev = [...prev];
      const checkIndex = prev.findIndex((e) => e?.titulo === data?.titulo);

      if (checkIndex >= 0) {
        newPrev.splice(checkIndex, 1);
      }

      return newPrev;
    });
  }

  return (
    <Container>
      <Label title="Itens *" />
      {!(editShow && edit) && (
        !disabled && (
          <div className="Etapa_div_addResponsaveis">
            <Input name="item" required="true" defaultValue={responsavel?.titulo} disabled={disabled} onChange={(e) => {
              const v = e.currentTarget.value || "";

              setResponsavel({ titulo: v, id: 0, ordem: 0 })
            }} />
            {!disabled && (
              <ColoredButton small blueLayout type="button" onClick={handleAddResponsavel}>
                Adicionar
              </ColoredButton>
            )}
          </div>
        )
      )}
      <div className="list-itens">
        {
          data?.map((item) => (
            <div className="item row">
              <div className="col s1 m1 l1 item-icon no-margin">
                <IconButton
                  className="SingleValueList_div_IconButton"
                  materialIcon={{ close: true }}
                  onClick={() => !disabled && handleRemoveResponsavel(item)}
                />
              </div>
              <div className="col s6 m6 l6 no-margin">
                <p>{FindKeyValue("titulo", item)}</p>
              </div>
              <Label title="Ordem:" className="col s5 m5 l5 align-left no-margin">
                <Input
                  name="ordem"
                  required="true"
                  defaultValue={item?.ordem}
                  onChange={(e) => {
                    const v = e.currentTarget.value || 0;

                    let lista = [];
                    for (const itemSelected of data) {
                      if (itemSelected?.titulo == item?.titulo) {
                        lista.push({ ...itemSelected, ordem: v });
                      } else {
                        lista.push(itemSelected);
                      }
                    }

                    setter(lista);
                  }}
                />
              </Label>
              {/* <div className="col s4 m4 l4">
                <p>{FindKeyValue("titulo", e)}</p>
              </div> */}
            </div>
          ))
        }
      </div>
      {/* <SingleValueList
        data={dfSelected}
        dataKey="titulo"
        disabled={disabled}
        {...{ ...(editShow && edit ? {} : { materialIcon: "close" }) }}
        iconClick={handleRemoveResponsavel}
      /> */}
    </Container>
  );
}

export default ItensList;
