import Scrollbars from "react-custom-scrollbars";
import styled from "styled-components";
import { Colors } from "~/config/Colors";
import Card from '~/pages/Sistema/componentes/Card';

export const Container = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 1.5em;
  background: ${Colors.Color40};
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background: ${Colors.Color39};
  border-radius: 1.5em;
  padding: 1.5em;

  .carregando {
    width: 100%;

    .logo-fundo {
      width: 4em;
    }

    .titulo {
      font-size: 0.8em;
      font-weight: 800;
      color: ${Colors.Color41};
    }
    
    .subtitulo {
      font-size: 0.55em;
      font-weight: 500;
      color: ${Colors.Color42};
    }
    
    .marcador {
      content: "";
      background: ${Colors.Color43};
      width: 2.5em;
      height: 0.2em;
      margin-top: 0.5em;
      border-radius: 1.5em;
    }
  }

  .pesquisa {
    width: 100%;
    height: 100%;

    .header {
    
      .titulo {
        font-size: 0.85em;
        font-weight: 800;
        color: ${Colors.Color41};
      }
      
      .subtitulo {
        font-size: 0.65em;
        font-weight: 500;
        color: ${Colors.Color42};
      }
    }
    
    .itens-coment {
      margin-top: 1.5em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 90%;
    
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1.25em;
        width: 100%;
        cursor: pointer;
        
        .titulo {
          font-size: 0.85em;
          font-weight: 600;
          color: ${Colors.Color41};
          margin-left: 1.3em;
        }
      }

      textarea {
        width: 100%;
        font-size: 0.75em;
        color: ${Colors.Color41} !important;
        margin-top: 1em;
      }

      .btn {
        margin-top: 1.6em;
        border: 0;
        padding: 0.7em 1em;
        font-size: 1em;
        border-radius: 0.5em;
        background: ${Colors.Color43};
        color: ${Colors.Color39};
      }
    }

    .itens {
      margin-top: 2em;

      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1.25em;
        width: 100%;
        cursor: pointer;
        
        .icon {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-right: 1.3em;

          img {
            width: 2em;
          }
        }
        
        .titulo {
          font-size: 0.85em;
          font-weight: 600;
          color: ${Colors.Color41};
        }
      }

      textarea {
        width: 100%;
        font-size: 0.75em;
        color: ${Colors.Color41} !important;
      }

      .btn {
        margin-top: 1.6em;
        border: 0;
        padding: 0.5em 0.8em;
        font-size: 0.75em;
        border-radius: 0.5em;
        background: ${Colors.Color43};
        color: ${Colors.Color39};
      }
    }
  }

  .body-mobile {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .valeu {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .header {
      margin-bottom: 1em;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    
      .titulo {
        font-size: 1.2em;
        font-weight: 800;
        color: ${Colors.Color23};
      }
      
      .subtitulo {
        font-size: 0.8em;
        font-weight: 500;
        color: ${Colors.Color12};
      }
    }

    .itens-coment {
      margin-bottom: 10em;

      img {
        width: 100%;
      }
    }
  }
`;