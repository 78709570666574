import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const ContainerTable = styled.div`
  padding: 0em;
  width: 100%;
  background: ${Colors.Color2};
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;

  thead {
    background: ${Colors.Color8};
  }

  th {
    /* TABLE */
    color: ${Colors.Color15};
    height: 3em;
    font-weight: 800;
    font-size: 0.8em;
  }

  th:first-child {
    border-radius: 0.72em 0 0 0.72em;
  }

  th:last-child {
    border-radius: 0 0.72em 0.72em 0;
  }

  td {
    /* border-bottom: solid 0.1em #f3f3f3; */
    min-height: 2.6em;
    font-weight: 400;
    font-size: 0.72em;
    color: ${Colors.Color15};

    :empty {
      height: 3.1em;
    }
  }

  td,
  th {
    white-space: nowrap;
    /* overflow: hidden;
    text-overflow: ellipsis; */
    line-break: none;
    padding: 1em 0.56em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-align: left;
  }

  tbody {
    tr {
      cursor: pointer;
      // border: 2px solid ${Colors.Color8};
    }

    td:first-child {
      border-radius: 0.72em 0 0 0.72em;
    }

    td:last-child {
      border-radius: 0 0.72em 0.72em 0;
    }

    tr:nth-child(2n) {
      background: ${Colors.Color26};
    }

    tr.ativo {
      background: ${Colors.Color23};
      border-radius: 1em;

      td {
        font-weight: 500;
        color: ${Colors.Color2};
      }
    }

    tr:hover {
      background: ${Colors.Color23};

      td {
        font-weight: 500;
        color: ${Colors.Color2};
      }
    }
  }

  .text-center {
    text-align: center;
  }

  .truncate {
    width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .col-w-1 {
    max-width: 5em;
    min-width: 6em;
    width: 5em;
  }

  .col-w-2 {
    max-width: 10em;
    min-width: 10em;
    width: 10em;
  }

  .col-w-3 {
    max-width: 15em;
    min-width: 15em;
    width: 15em;
  }

  .col-w-4 {
    max-width: 20em;
    min-width: 20em;
    width: 20em;
  }

  .col-w-5 {
    max-width: 25em;
    min-width: 25em;
    width: 25em;
  }

  .col-w-6 {
    max-width: 30em;
    min-width: 30em;
    width: 30em;
  }

  .col-w-7 {
    max-width: 35em;
    min-width: 35em;
    width: 35em;
  }

  .col-w-8 {
    max-width: 40em;
    min-width: 40em;
    width: 40em;
  }

  .col-w-9 {
    max-width: 45em;
    min-width: 45em;
    width: 45em;
  }

  .col-w-10 {
    max-width: 50em;
    min-width: 50em;
    width: 50em;
  }
`;
