import styled from 'styled-components';
import SistemaList from '~/pages/Sistema/componentes/SistemaList';
import { Colors } from '~/config/Colors';

export const Container = styled.div`
  margin: 0;
  width: 450px;
  font-size: 12px;
  background: #fffff;

  p, pre {
    font-family: 'Nunito', 'cursive';
  }

  pre {
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .barra-sup {
    background: #2ED3B5;
    width: 100%;
    height: 13px;
    content: "";
  }

  .header {
    padding: 1em 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      width: 70px;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .titulo {
        font-size: 9px;
        color: #787979;
      }

      .titulo-relatorio {
        font-size: 11px;
        color: #787979;
      }

      .titulo-data {
        font-size: 9px;
        color: #A4A2A2;
      }
    }
  }
  
  .body {
    padding: 0em 2em;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .text {
        .titulo {
          font-size: 11px;
          color: #5269A7;
        }

        .sub-titulo {
          font-size: 9px;
          color: #A4A2A2;
        }
      }
    }

    .mid {
      display: flex;
      flex-direction: row;
      align-items: align-start;
      justify-content: space-between;
      width: 100%;
      border-radius: 6px;
      background: #EFF1F8;
      padding: 0.5em;

      .rigth {
        align-items: flex-end;
      }
      
      .col {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 6px;
        padding: 0.5em 1em;

        .linha {
          display: flex;
          flex-direction: row;
          
          .titulo {
            font-size: 0.5rem !important;
            color: #333233 !important;
          }

          .valor {
            font-size: 0.5rem !important;
            color: #737373 !important;
            margin-left: 0.2em;
          }
        }
      }
      
    }

    .section-2 {
      margin-top: 150px;
    }

    .section-1 {
      width: 100%;
      padding: 0em 1em;
      margin-top: 1rem;

      .chart-total {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .chart-tt-circle {
          width: 100%;
          height: 6em;
          border-radius: 1em;
          background: #F6F8FB;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 1em;

          .ct-percent {
            width: fit-content;
            font-size: 1.2em;
            font-weight: 800;
          }

          .divisor {
            content: "";
            width: 2em;
            height: 0.2em;
            border-radius: 1em;
            margin: 0em 0em 0.2em 0;
          }

          .dv-colo1 {
            background: #2ed3b5;
          }

          .dv-colo2 {
            background: #B6DF53;
          }

          .dv-colo3 {
            background: #FFD147;
          }

          .dv-colo4 {
            background: #FFAB48;
          }

          .dv-colo5 {
            background: #FF5780;
          }

          .ct-title {
            width: fit-content;
            font-size: 0.6rem;
            font-weight: 800;
          }
            
          .ct-quant {
            width: fit-content;
            font-size: 0.9em;
            font-weight: 800;
            color: #969AAA;
          }
        }
      }

      .title {
        width: fit-content;
        font-size: 0.7em;
        font-weight: 800;
        color: #999999;
        margin-bottom: 1em;
      }

      .list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .item-pergunta {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 0.6rem;

          img {
            width: 1.1em;
          }

          .titulo-item {
            width: fit-content;
            font-size: 0.6em;
            font-weight: 600;
            color: #999999;
          }

          .progress {
            width: 100%;
            margin-left: 0.5em;
            border-radius: 1em;
            background: #f1f1f1;
            height: 0.6em;
          }

          .align-right {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
          }

          .align-left {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }

          .align-center {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }

          .col-1 {
            width: 5rem;
          }
          
          .col-1-1 {
            width: 3rem;
          }
          
          .col-2 {
            width: 10rem;
          }

          .col-3 {
            width: 15rem;
          }

          .col-4 {
            width: 20rem;
          }
          
          .col-5 {
            width: 25rem;
          }

          .col-6 {
            width: 30rem;
          }
        }
      }

    }

  }
`;

export const ContainerComm = styled(SistemaList)`
  height: 100%;
  overflow: auto;
`;

export const ThisProgress = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  content: "";
  width: ${props => props?.width};
  height: 0.6em;
  border-radius: 1em;
  background: ${props => props?.backgound};
`;

export const ContainerTable = styled.div`
  padding: 0em;
  width: 100%;
  background: #FFFFFF;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;

  thead {
    background: #F8F8F8;
  }

  th {
    /* TABLE */
    color: #969AAA;
    height: 3em;
    font-weight: 800;
    font-size: 0.8em;
  }

  th:first-child {
    border-radius: 0.72em 0 0 0.72em;
  }

  th:last-child {
    border-radius: 0 0.72em 0.72em 0;
  }

  td {
    /* border-bottom: solid 0.1em #f3f3f3; */
    min-height: 2.6em;
    font-weight: 400;
    font-size: 0.72em;
    color: #969AAA;

    :empty {
      height: 3.1em;
    }
  }

  td,
  th {
    white-space: nowrap;
    /* overflow: hidden;
    text-overflow: ellipsis; */
    line-break: none;
    padding: 1em 0.56em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-align: left;
  }

  tbody {
    tr {
      cursor: pointer;
      // border: 2px solid #F8F8F8;
    }

    tr.back {
      background: #F8F8F8 !important;
    }

    td:first-child {
      border-radius: 0.72em 0 0 0.72em;
    }

    td:last-child {
      border-radius: 0 0.72em 0.72em 0;
    }
  }

  .text-center {
    text-align: center;
  }

  .truncate {
    width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .col-w-1 {
    max-width: 5em;
    min-width: 6em;
    width: 5em;
  }

  .col-w-2 {
    max-width: 10em;
    min-width: 10em;
    width: 10em;
  }

  .col-w-3 {
    max-width: 15em;
    min-width: 15em;
    width: 15em;
  }

  .col-w-4 {
    max-width: 20em;
    min-width: 20em;
    width: 20em;
  }

  .col-w-5 {
    max-width: 25em;
    min-width: 25em;
    width: 25em;
  }

  .col-w-6 {
    max-width: 30em;
    min-width: 30em;
    width: 30em;
  }

  .col-w-7 {
    max-width: 35em;
    min-width: 35em;
    width: 35em;
  }

  .col-w-8 {
    max-width: 40em;
    min-width: 40em;
    width: 40em;
  }

  .col-w-9 {
    max-width: 45em;
    min-width: 45em;
    width: 45em;
  }

  .col-w-10 {
    max-width: 50em;
    min-width: 50em;
    width: 50em;
  }
`;

export const ThisTr = styled.div`
  
  tr {
    background: #F8F8F8 !important;
  }

`;