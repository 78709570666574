import styled from "styled-components";
import { Colors } from "~/config/Colors";

export const Menu = styled.div`
  position: relative;
  width: 14em;
  height: 100%;
  color: ${Colors.Color7};
  background: ${Colors.Color1};
  transition: 0.5s;
  padding: 0em 1.2em 1em 0.4em;
  
  .menu-scroll {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0em;
    transition: 0.5s;
    height: 100%;
    margin-top: 0em;

    .menu-full {
      width: 100%;
    }

    ${({ showMaxMenu }) => {
      if (showMaxMenu) {
        return `overflow: auto; width: 100%;`;
      } else {
        return `width: 0em; overflow: hidden;`;
      }
    }}

    .menu-button {
      width: 100%;
      margin-bottom: 0.8em;
      margin-top: 1em;
      padding: 0 1em;
    }
  }
`;
