import styled from "styled-components";
import Button from "~/componentes/Button";
import { Colors } from "~/config/Colors";

export const ThisButton = styled(Button)`
  position: relative;
  width: 100%;
  height: 2em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 0;
  padding: 0 0.8em;
  border-radius: 0.45em;

  ${(props) => {
    return props?.selected == true ? `background: ${Colors.Color11};` : `background: ${Colors.Color1};`;
  }}

  :before {
    position: absolute;
    content: "";
    width: 0.2em;
    height: 1em;
    
    left: 0;
    border-radius: 1em;
    ${(props) => {
      return props?.selected == true ? `background: ${Colors.Color23};` : ``;
    }}
  }


  :hover {
    background: ${Colors.Color11};
  }

  img {
    width: 1.2em;
    margin-right: 0.7em;
  }

  p {
    color: ${Colors.Color12};
    font-size: 0.8em;
    font-weight: 600;
  }
`;
